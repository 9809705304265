
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Data from "@/data/Ht-Tr-technology/Data.json";
import List from "@/data/Ht-Tr-technology/List.json";
import moment from "moment";
import Materials from "@/data/Materials.json";

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    Data: any;
    List: Array<any>;
    Step: number;
    Type: number;
    Item: any;
    Index: any;
    Page: any;
  } {
    return {
      Data: Data,
      List: Materials,
      Step: 1,
      Type: 0,
      Item: {},
      Index: 0,
      Page: 1,
    };
  },
  methods: {
    DayFormatter(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    New() {
      this.Item = {
        Date: "",
        No: "",
        FurnaceNumber: "",
        Material: "",
        Diameter: "",
        Elements: [
          {
            C: "",
            Si: "",
            Mn: "",
            P: "",
            S: "",
            Ni: "",
            Cr: "",
            Mo: "",
            Al: "",
            Co: "",
            Cu: "",
          },
        ],
      };
      this.Type = 0;
      this.Step = 2;
    },
    Edit(item, index) {
      this.Index = index;
      this.Type = 1;
      this.Item = item;
      this.Step = 2;
    },
    Delete(index) {
      var yes = confirm("是否確定刪除？");
      if (yes) {
        this.List.splice(index, 1);
        alert("刪除成功");
      }
    },
    Save() {
      if (this.Type == 0) {
        this.List.unshift(this.Item);
        alert("新增成功");
      } else {
        this.List[this.Index] = this.Item;
        alert("修改成功");
      }
      this.Step = 1;
    },
    async SwitchPage(page) {
      if (page !== 0) {
        this.Page = page * 10 + 1;
      } else {
        this.Page = page + 1;
      }
    },
  },
  created() {
    this.List.sort((a, b) => {
      let aDate = moment(new Date(a.Date)).format("YYYY-MM-DD");
      let bDate = moment(new Date(b.Date)).format("YYYY-MM-DD");
      // return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
      return Date.parse(aDate) - Date.parse(bDate);
    });
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("來料分析列表", ["工單與來料管理"]);
    });
    return {};
  },
});
